import React, {FC} from 'react';
import {AppBar, Toolbar, Button, useTheme} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import { useAppSelector } from '../../../Config/Hooks';

interface CustomAppBarProps {
    handleSave: () => void;
    handleBack: () => void;
    rows?: any;
}


const CustomAppBar: FC<CustomAppBarProps> = ({ handleSave, handleBack, rows }) => {
    const contentPagePaddingTop = useAppSelector((state: any) => state.layout.contentPagePaddingTop);
 
    const isSmallScreen = window.innerWidth <= 400;
    const isMedianScreen = window.innerWidth <= 495;

    const theme = useTheme()
    return (
        <AppBar position="fixed" style={{ paddingTop: isSmallScreen ? 96 : isMedianScreen ? 84 : contentPagePaddingTop}}>
            <Toolbar style={{backgroundColor: theme.palette.background.default, boxShadow: '0px 3px 6px #00000029'}}>
                <Button
                    variant="contained"
                    size={"small"}
                    color={"primary"}
                    endIcon={<SaveIcon/>}
                    style={{marginLeft: '22%'}}
                    disabled={rows && rows.length === 0}
                    onClick={handleSave}
                >
                    Salvar
                </Button>
                <Button
                    variant={theme.palette.mode === 'light' ? "outlined" : "contained"}
                    size={"small"}
                    color={"primary"}
                    endIcon={<CancelOutlined />}
                    style={{marginLeft: '1%'}}
                    onClick={handleBack}
                >
                    Cancelar
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;