import {Grid, useTheme} from "@mui/material";
import * as React from "react";
import {AChartX, ACard, Skeleton} from "@atiautomacao/ati-ui-library";
import {FunctionComponent, useEffect, useState} from "react";
import {ChartAnalysisReactQuery, handleStartDateTimeToRecent} from "./AnalysisHomeReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import {AChartXProps} from "@atiautomacao/ati-ui-library/dist/components/AChartX/AChartX";
import {ChartConfigType} from "../../Shared/Types/chartConfigType";
import {format} from "date-fns";
import {useSnackbar} from "notistack";
import {EChartType} from "../../Shared/Types/Echart/echart";
import DataNotFound from "../../Shared/Components/DataNotFoundMessage";

export const styleRightYAxis = {
    color:'#454554',
    fontStyle: 'italic',
    fontWeight: 'bold'
}

export const sortYAxisPosition = (chartConfig: EChartType) => {
    chartConfig.yAxis.sort((a, b) =>{
        if(a?.position && b?.position && a?.position < b?.position) return -1;
        if(a?.position && b?.position && a?.position > b?.position) return 1;
        return 0;
    });
}

export const sortYIndex = (chartConfig: EChartType) => {
    chartConfig.series.sort((a:any, b:any) =>{
        if(a.yAxisIndex < b.yAxisIndex) return -1;
        if(a.yAxisIndex > b.yAxisIndex) return 1;
        return 0;
    });
}

export const configUnityDistinct = (unityDistinct:(string | null)[], y:any, index: number, yAxisLength: number) => {
    if(unityDistinct.length === 2){
        if(y.position === 'LEFT' && index === 0 && unityDistinct[0] != null) {
            y.name = `[${unityDistinct[0]}]`;
        } else if(y.position === 'RIGHT' && index === yAxisLength && unityDistinct[1] != null) {
            y.name = `[${unityDistinct[1]}]`;
            y.nameTextStyle = styleRightYAxis
            y.axisLabel = styleRightYAxis
        } else {
            y.name = "";
            y.axisLabel = styleRightYAxis
        }
    } else if(y.position === 'LEFT' && index === 0 && unityDistinct[0] != null) {
        y.name = `[${unityDistinct[0]}]`;
    } else if(y.position === 'RIGHT' && index === yAxisLength && unityDistinct[0] != null) {
        y.name = `[${unityDistinct[0]}]`;
        y.nameTextStyle = styleRightYAxis
        y.axisLabel = styleRightYAxis
    }
    else {
        y.name = "";
        y.axisLabel = styleRightYAxis
    }
}

export const generateLegendData = (chartConfig: EChartType) => {
    return chartConfig.yAxis.map((y:any) => {
        let nameY = chartConfig.series.filter(serie => serie.tag === y.tag)[0]?.name;
        if(y.position === 'RIGHT'){
            const legend:any = {
                name: nameY ? nameY : "",
                textStyle: styleRightYAxis
            }
            return legend
        }
        
        return {
            name: nameY ? nameY : "",
        }
    })
}
export const Charts:FunctionComponent<{ chartConfigList: ChartConfigType[]}> = ({chartConfigList}) => {
    const [dataEChartConfig, setDataEChartConfig] = useState<EChartType[]>([]);
    const [actualIndex, setActualIndex] = useState<number>(0)
    const [loadingStates, setLoadingStates] = useState<boolean[]>(chartConfigList.map(() => true));
    const [errorStates, setErrorStates] = useState<boolean[]>(chartConfigList.map(() => false));
    const [chartConfig, setChartConfig] = useState<ChartConfigType[]>([])
    const {data, error, isSuccess, isLoading, isError} = ChartAnalysisReactQuery(chartConfig);
    const [content, setContent] = useState<JSX.Element[]>()
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const handleChartOption = (chartConfig: EChartType) => {

        const unityByIdDistinct:(string|null)[] = Array.from(new Set());

        sortYAxisPosition(chartConfig)
        sortYIndex(chartConfig)

        chartConfig.yAxis.forEach((yAxis:any) => {
            if(yAxis.chartSourceConfig.teleObject != null) {
                if(yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.name) {
                    if (!unityByIdDistinct.find(unityValue => yAxis.chartSourceConfig?.teleObject?.teleObjectConfig?.unity?.name === unityValue)) {
                        unityByIdDistinct.push(yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.name)
                    }
                } else {
                    unityByIdDistinct.push(null);
                }

            } else if(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.unity.name != null && !unityByIdDistinct.find(unityValue => yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.unity.name === unityValue)) {
                unityByIdDistinct.push(yAxis.chartSourceConfig?.aggregatedDataConfig.dbColumn.unity.name);
            }
        })
        const unityDistinct = Array.from(new Set(unityByIdDistinct))
        const yAxisLength = chartConfig.yAxis.length - 1
        chartConfig.yAxis.map((y: any, index:number) => {
            configUnityDistinct(unityDistinct, y, index, yAxisLength)
            y.type= 'value'
            y.nameLocation = 'middle'
            y.nameGap = 75
            y.axisLine= {
                show: true
            }
            y.axisTick= {
                show: true
            }
            return y
        })

        let i;
        for (i = 0; i < chartConfig.series.length; i++) {
            chartConfig.series[i].formatter = function (value:any) {
                return value
            }
            if( chartConfig.series[i].yAxisIndex === 0){
                chartConfig.series[i].yAxisIndex = null;
            }
            if(chartConfig.series[i]?.dataByTime && chartConfig.series[i].dataByTime.length > 0){
                if(chartConfig.yAxis.length === 1) {
                    chartConfig.series[i].yAxisIndex=null;
                }
                chartConfig.series[i].data=chartConfig.series[i].dataByTime;
            }

        }

        chartConfig.xAxis.map((x: any) => {
            x.nameLocation = 'center'
            x.nameGap = 27
            if(x.type === 'time' && x.axisLabelFormatter) {
                x.nameGap=40
                x.axisLabel = {
                    rotate: -45,
                    show: true,
                    inside: false,
                    formatter: x.axisLabelFormatter,
                    // showMinLabel: true,
                    // showMaxLabel: true,
                    align: "bottom",
                }
            }
            if(x.name === 'Datas') {
                x.nameGap=70
            }
            return x
        })
        chartConfig.xAxis = chartConfig.xAxis.filter((item: any, index: number) => index === 0)
        const option: AChartXProps['option'] = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                        formatter:chartConfig.xAxis.length > 0 && chartConfig.xAxis[0].type === 'time' ?
                            function (params:any) {
                                if (params?.seriesData && params?.seriesData.length > 0 && Array.isArray(params?.seriesData[0].value) && params?.seriesData[0].value.length > 0){
                                    return format(new Date(params?.seriesData[0].value[0]), 'dd/MM/yyyy HH:mm:ss')
                                } else {
                                    return '-';
                                }
                            }
                            : undefined
                    }
                },
            },
            grid: {
                containLabel: true,
                top: 25,
                bottom: 50
            },
            ...chartConfig,
            legend: {
                show: true,
                data: generateLegendData(chartConfig),
                type: 'scroll',
                orient: 'horizontal',
                bottom: 0,
                icon: 'none',
                width: '100%', // Adjust this width to control how many items per line
                itemWidth: 10, // Adjust the width of each legend item (optional)
                itemHeight: 14, // Adjust the height of each legend item (optional)
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                tooltip: {
                    show: true, // Enables tooltip on legend hover
                    formatter: function(name:any) {                        
                        const nameLegend = name?.name;
                        // Customizes the legend tooltip content
                        return `${nameLegend}`;
                    }
                },
                // @ts-ignore
                formatter : function(name: any) {
                    let info = name.split(" - ");                  
                    let ps = info.length > 1 ? info[info.length -1] : null
                    ps = ps ? ps.substr(0,3) : "";
                    let eqName = info.length > 3 ? info[1] : null
                    eqName = eqName ? eqName.substr(0,4) : ""
                    let to = info.length >= 1 ? info[0] : ""

                    return `{bullet|${ps}} {legendtext|${ eqName ? eqName + " - " + to : to}}`;

                },
                textStyle: {
                        rich: {
                            bullet: {
                                color: '#fff', // Text color
                                backgroundColor: function(params: any) {
                                    return params.color;
                                },
                                borderRadius: 4, // Rounded corners
                                padding: [2, 4], // Padding around the text
                                align: 'center', // Center the text
                                fontWeight: 'bold'
                            },
                            legendtext: {
                                color: theme.palette.mode === 'light' ? '#000' : undefined, // Legend name color
                                padding: [0, 0, 0, 4], // Adjust padding between text and legend name
                            },
                        },
                },
            },
            toolbox: {
                right: '10%',
                top: -8,
                itemSize: 15,
                feature: {
                    dataView: { show: false, readOnly: false },
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    restore: { show: true },
                    magicType: { show: false, type: ['line', 'bar'] },
                    saveAsImage: { show: false }
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    throttle: 50
                },
            ],
        }
        return option;
    }

    useEffect(() => {
        if(isSuccess){

            if(dataEChartConfig != null && actualIndex < chartConfigList.length){
                let dataEChartConfigCopy = data?.data;
                if(dataEChartConfig && dataEChartConfig?.length > 0) {
                    const newEChartConfig = [...dataEChartConfig, ...dataEChartConfigCopy]
                    if(newEChartConfig.length <= chartConfigList.length){
                        setDataEChartConfig(newEChartConfig);
                    }
                }else {
                    setDataEChartConfig(dataEChartConfigCopy)
                }
            }
            setActualIndex(prevState => prevState + 1)
            setLoadingStates(prevState => prevState.map((loading,index) => {
                if(index === actualIndex){
                    return false;
                }
                return loading
            }))
        }
    }, [data?.data]);

    useEffect(() => {
        if(actualIndex < chartConfigList.length && actualIndex >= 0){
            setChartConfig([chartConfigList[actualIndex]])
        }
    }, [actualIndex]);

    useEffect(() => {
        if(chartConfigList != null && chartConfigList.length > 0){
            setLoadingStates(chartConfigList.map(() => true))
            setErrorStates(chartConfigList.map(() => false))
        }
        setDataEChartConfig([])
        setActualIndex(0)
    }, [chartConfigList]);

    useEffect(() => {
        if(actualIndex >= chartConfigList.length){
            setActualIndex(-2)
            setChartConfig([])
        }
    }, [dataEChartConfig]);

    useEffect(() => {
        if(isError){
            // @ts-ignore
            const status = error?.response?.status

            if (status === 400) {
                // @ts-ignore
                const valueMaxLimit = (error.response?.data?.detail?.match(/\d+/g) || []).join("");
                // @ts-ignore
                const detailsMessage = error.response?.data?.detail;
                if(detailsMessage.includes("Selected period exceeds the limit of")){
                    enqueueSnackbar(`Período selecionado ultrapassa o limite de ${valueMaxLimit.length > 0 ? + valueMaxLimit : ""}
                        registros.`, {variant: 'error'});
                } else {
                    // @ts-ignore
                    const valueMaxLimit = (error.response?.data?.detail?.match("measure") || []).join("");
                    if(valueMaxLimit) {
                        enqueueSnackbar("Não foi possivel exibir todos os gráficos.", {variant: 'warning'})
                        enqueueSnackbar("Análise não configurada corretamente. Ajuste o tipo de agregação.", {variant: 'error'})
                    }
                }

            } else{
                enqueueSnackbar("Não foi possível buscar os dados. Tente novamente", {variant: 'error'})
            }
            setErrorStates(prevState => prevState.map((status,index) => {
                if(index === actualIndex){
                    return true;
                }
                return status
            }))
            setActualIndex(prevState => prevState + 1)
        }

        }, [error]);

    useEffect(() => {
        if (dataEChartConfig != null && dataEChartConfig.length > 0 && errorStates.length > 0 && loadingStates.length > 0) {
            let validDataIndex = 0;
            setContent(loadingStates.map((loading, index) => {
                        if (errorStates[index]) {
                            return (
                                <Grid item xs={12} sm={12} md={12} key={index.valueOf()}>
                                    <ACard>
                                        <DataNotFound boxStyle={{ height: 300, width: '100%' }} />
                                    </ACard>
                                </Grid>
                            );
                        } else if (loading) {
                            return (
                                <Grid item xs={12} sm={12} md={12} key={index.valueOf()}>
                                    <ACard>
                                        <Skeleton animation="wave" height={300} variant="rounded" width="100%" />
                                    </ACard>
                                </Grid>
                            );
                        } else {                            
                            const dataConfig = dataEChartConfig[validDataIndex % dataEChartConfig.length];
                            validDataIndex++;
                            const option = handleChartOption(dataConfig);
                            {
                                if (option.series && option.series?.length > 0) {
                                    return (
                                        <Grid item xs={12} sm={12} md={12} key={`${dataConfig.name}-${dataConfig.yAxis}-${index.valueOf()}`}>
                                            <ACard title={dataConfig.name}>
                                                <AChartX
                                                    key={`${index}-${dataConfig.name}-${dataConfig.yAxis}`}
                                                    option={option}
                                                    height={300}
                                                    loading={false}
                                                    theme={theme.palette.mode === 'light' ? undefined : theme.palette.mode}
                                                />
                                            </ACard>
                                        </Grid>
                                    );
                                }
                                return <Grid item xs={12} sm={12} md={12} key={index.valueOf()}>
                                    <ACard
                                        key={`second-card-${dataConfig.name}`}
                                        title={dataConfig.name}
                                        styleProps={{
                                            cardStyle: {height:400}
                                        }}
                                    >
                                        <DataNotFound boxStyle={{ height: 300, width: '100%'}} />
                                    </ACard>
                                </Grid>;
                            }
                        }
            }))
        }
    }, [dataEChartConfig, errorStates, loadingStates]);

    return (
        <>
            {
                dataEChartConfig != null && dataEChartConfig.length > 0 && errorStates.length > 0 && loadingStates.length > 0 && content != null?
                    (
                        content.map(item => item)
                    )
                : isLoading && (
                    <Grid item xs={12} sm={12} md={12}>
                        <Skeleton animation="wave" height={300} variant="rounded" width="100%" />
                    </Grid>
                ) || (
                    <Grid item xs={12} sm={12} md={12}>
                        <ACard>
                            <DataNotFound boxStyle={{ height: 300, width: '100%' }}/>
                        </ACard>
                    </Grid>
                )
            }
        </>
    );
}

export const ChartAnalysis:FunctionComponent<{ chartConfig: ChartConfigType[] }> = ({chartConfig}) =>{

    const queryClient = new QueryClient();

    useEffect(() => {
        chartConfig.map(value => {
            const endDateTime = new Date(value.endDateTime);
            if(value.periodType === 'RECENT' && endDateTime.getDay() !=  new Date().getDay()){
                value.endDateTime = new Date();
                const startDateTime = handleStartDateTimeToRecent(value.recentPeriod ?? 7, value.groupingRecentByPeriod ?? 'day');
                value.startDateTime = startDateTime ?? new Date();
            }
            return value;
        })
    }, [chartConfig]);
    return(
        <QueryClientProvider client={queryClient}>
            <Charts chartConfigList={chartConfig}/>
        </QueryClientProvider>
    )
}
