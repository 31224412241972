import {Grid, useTheme} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {ACard, AChartX, Skeleton} from "@atiautomacao/ati-ui-library";
import {useLocation, useParams} from "react-router-dom";
import {AnalysisHomeReactQuery} from "./AnalysisHomeReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import {AChartXProps} from "@atiautomacao/ati-ui-library/dist/components/AChartX/AChartX";
import AnalysisHeaderMenu from "./AnalysisHeaderMenu";
import {format} from "date-fns";
import {useSnackbar} from "notistack";
import {EChartType} from "../../Shared/Types/Echart/echart";
import {generateLegendData, sortYAxisPosition, sortYIndex, configUnityDistinct} from "./ChartAnalysis"
import DataNotFound from "../../Shared/Components/DataNotFoundMessage";
import {useAppSelector} from "../../Config/Hooks";
import {hasPermission} from "../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../Config/Constants";

export const AnalysisCharts = () => {
    const theme = useTheme();
    const params = useParams();
    const location = useLocation();
    const chartConfigList = location.state?.chartList; // Aqui você acessa o chartConfigList
    const { enqueueSnackbar } = useSnackbar();

    const [dataEChartConfig, setDataEChartConfig] = useState<EChartType[]>([]);
    const [chartConfigIdList, setChartConfigIdList] = useState<any[]>([]);
    const [chartConfigId, setChartConfigId] = useState<number| null>(null);
    const [actualIndex, setActualIndex] = useState(-1);

    const {data, error, isSuccess, isError, isLoading} = AnalysisHomeReactQuery(chartConfigId);


    const handleChartOption = (chartConfig: EChartType) => {
        chartConfig.xAxis = chartConfig.xAxis.filter((item: any, index: number) => index === 0)

        const unityByIdDistinct:(string|null)[] = Array.from(new Set());
        sortYAxisPosition(chartConfig)
        sortYIndex(chartConfig)

        chartConfig.yAxis.forEach((yAxis:any) => {
            if(yAxis.chartSourceConfig.teleObject != null) {
                if(yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.name) {
                    if (!unityByIdDistinct.find(unityValue => yAxis.chartSourceConfig.teleObject?.teleObjectConfig?.unity?.name === unityValue)) {
                        unityByIdDistinct.push(yAxis.chartSourceConfig.teleObject.teleObjectConfig?.unity?.name)
                    }
                } else {
                    unityByIdDistinct.push(null);
                }

            } else if(yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.unity.name != null && !unityByIdDistinct.find(unityValue => yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.unity.name === unityValue)) {
                unityByIdDistinct.push(yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn.unity.name);
            }
        })
        const unityDistinct = Array.from(new Set(unityByIdDistinct))
        const yAxisLength = chartConfig.yAxis.length - 1
        chartConfig.yAxis.map((y: any, index:number) => {
            configUnityDistinct(unityDistinct, y, index,yAxisLength)
            y.nameLocation = 'middle'
            y.nameGap = 75
            return y
        })
        let i;
        for (i = 0; i < chartConfig.series.length; i++) {
            chartConfig.series[i].formatter= function (value:any) {
                return value
            }
            if(chartConfig.series[i]?.dataByTime && chartConfig.series[i].dataByTime.length > 0){
                chartConfig.series[i].data=chartConfig.series[i].dataByTime;
            }

        }

        chartConfig.xAxis.map((x: any) => {
            x.nameLocation = 'center'
            x.nameGap = 25
            if(x.type === 'time' && x.axisLabelFormatter) {
                x.nameGap=40
                x.axisLabel = {
                    rotate: -45,
                    formatter: x.axisLabelFormatter,
                    align: "left",
                }
            }
            if(x.name === 'Datas') {
                x.nameGap=70
            }
            x.animationDuration= 1000
            x.animationDurationUpdate= 1000
            return x
        })
        const option: AChartXProps['option'] = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                        formatter:chartConfig.xAxis.length > 0 && chartConfig.xAxis[0].type === 'time' ?
                            function (params:any) {
                                if (params?.seriesData && params?.seriesData.length > 0 
                                    && Array.isArray(params?.seriesData[0].value) && params?.seriesData[0].value.length > 0) {
                                    return format(new Date(params?.seriesData[0].value[0]), 'dd/MM/yyyy HH:mm:ss')
                                } else {
                                    return '-';
                                }
                            }
                            : undefined
                    }
                },
            },
            grid: {
                containLabel: true,
                top: 25,
                bottom: 50
            },
            ...chartConfig,
            legend: {
                type: 'scroll',
                orient: 'horizontal',
                bottom: 0,
                show: true,
                icon: 'none',
                width: '100%', // Adjust this width to control how many items per line
                itemWidth: 10, // Adjust the width of each legend item (optional)
                itemHeight: 14, // Adjust the height of each legend item (optional)
                data: generateLegendData(chartConfig),
                tooltip: {
                    show: true, // Enables tooltip on legend hover
                    formatter: function(name:any) {
                        const nameLegend = name?.name;
                        // Customizes the legend tooltip content
                        return `${nameLegend}`;
                    }
                },
                // @ts-ignore
                formatter : function(name: any) {
                    let info = name.split(" - ");
                    let ps = info.length > 1 ? info[info.length -1] : null
                    ps = ps ? ps.substr(0,3) : "";
                    let eqName = info.length > 3 ? info[1] : null
                    eqName = eqName ? eqName.substr(0,4) : ""
                    let to = info.length >= 1 ? info[0] : ""

                    return `{bullet|${ps?.toUpperCase()}} {legendtext|${ eqName ? eqName + " - " + to : to}}`;

                },
                textStyle: {
                        rich: {
                            bullet: {
                                color: '#fff', // Text color
                                backgroundColor: function(params: any) {
                                    return params.color;
                                },
                                borderRadius: 4, // Rounded corners
                                padding: [4, 4, 2, 4], // Padding around the text
                                align: 'center', // Center the text
                                verticalAlign: 'middle', // Center the text
                                fontWeight: 'bold', // Bold text
                            },
                            legendtext: {
                                color: theme.palette.mode === 'light' ? '#000' : undefined, // Legend name color
                                padding: [0, 0, 0, 4], // Adjust padding between text and legend name
                            },
                        },
                },
                padding:3,
                backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.7)' : undefined
            },
            toolbox: {
                right: '10%',
                top: -7,
                itemSize: 15,
                feature: {
                    dataView: { show: false, readOnly: false },
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    restore: { show: true },
                    magicType: { show: false, type: ['line', 'bar'] },
                    saveAsImage: { show: false }
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    throttle: 50
                },
            ],
        }
        return option;
    }

    useEffect(() => {
        setDataEChartConfig([])
    }, []);

    useEffect(() => {        
        setDataEChartConfig([])
        if(params.chartConfigIdList){
            setActualIndex(0);
            setChartConfigIdList(params?.chartConfigIdList?.split(','));
        }
    }, [params.analysisId]);

    useEffect(() => {
        if(chartConfigIdList && chartConfigIdList.length > 0 && actualIndex < chartConfigIdList.length){
            setChartConfigId(chartConfigIdList[actualIndex]);
        }
    }, [actualIndex]);


    useEffect(() => {
        if(isSuccess){
            if (data?.data) {
                setActualIndex(prevIndex => prevIndex + 1);
                if (!dataEChartConfig.find(chartConfigElement => chartConfigElement.id === data.data.id)) {
                    let dataEChartConfigCopy = data?.data;
                    if (dataEChartConfig && dataEChartConfig?.length > 0) {
                        setDataEChartConfig([...dataEChartConfig, dataEChartConfigCopy]);

                    } else {
                        setDataEChartConfig([dataEChartConfigCopy])
                    }
                }
            }else if(data?.status === 204) {                
                setActualIndex(prevState => prevState + 1);
            }
        }
    }, [data]);

    useEffect(() => {
        if(error){
            // @ts-ignore
            if (error?.response?.status === 400) {
                // @ts-ignore
                const valueMaxLimit = (error.response?.data?.detail?.match(/\d+/g) || []).join("");
                // @ts-ignore
                const detailsMessage = error.response?.data?.detail;
                if(detailsMessage.includes("Selected period exceeds the limit of")){
                    enqueueSnackbar(`Período selecionado ultrapassa o limite de ${valueMaxLimit.length > 0 ? + valueMaxLimit : ""}
                        registros.`, {variant: 'error'});
                } else {
                    // @ts-ignore
                    const valueMaxLimit = (error.response?.data?.detail?.match("measure") || []).join("");
                    if(valueMaxLimit) {
                        enqueueSnackbar("Não foi possivel exibir todos os gráficos.", {variant: 'warning'})
                        enqueueSnackbar("Análise não configurada corretamente. Ajuste o tipo de agregação, na tela de editar.", {variant: 'error'})
                    }
                    // @ts-ignore
                }
            } else { // @ts-ignore
                if(error?.response?.status === 404) {
                    enqueueSnackbar('Não foi possível encontrar registros para o período selecionado. Tente mudar o Range de datas!', {variant: 'warning'});
                }
            }
            setActualIndex(prevState => prevState + 1)

        }
    }, [error]);

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);

    const account = useAppSelector((state: any) => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_ANALYSIS
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DELETE_ANALYSIS
    ]);

    const selectedChartConfig = chartConfigList?.find((config: { id: number }) => config.id === Number(chartConfigId));

    return (
        <>
            <AnalysisHeaderMenu
                analysisId={params.analysisId}
                disableSave={!isAuthorizedToSave}
                disableDelete={!isAuthorizedToDelete}
            />

            <Grid
                key={`analysis-home-page-${openSubMenu}`}
                container spacing={2}>
                {
                    dataEChartConfig != null && dataEChartConfig.length > 0 ?
                        dataEChartConfig.map((chartConfig, index) => {
                            const option = handleChartOption(chartConfig);
                            if(option.series && option.series?.length > 0) {
                                return (
                                    <Grid item xs={12} sm={8} md={6} key={chartConfig.id}>
                                        <ACard
                                            key={`card-${chartConfig.id}-${index}`}
                                            title={chartConfig.name}
                                        >
                                            <AChartX
                                                key={`${index}-${chartConfig.id}`}
                                                option={handleChartOption(chartConfig)}
                                                height={300}
                                                loading={false}
                                                theme={theme.palette.mode === 'light' ? undefined : theme.palette.mode}
                                            />
                                        </ACard>
                                    </Grid>
                                );
                            }
                            return (
                                <Grid item xs={12} sm={8} md={6} key={`not-found-${chartConfig.id}-${index}`}>
                                    <ACard
                                        key={`second-card-${chartConfig.id}`}
                                        title={chartConfig.name}
                                        styleProps={{
                                            cardStyle: {height:400}
                                        }}
                                    >
                                        <DataNotFound boxStyle={{ height: 300, width: '100%' }} />
                                    </ACard>
                                </Grid>
                            );
                        })
                        : (
                            <>
                                {
                                    isLoading && (
                                        <Grid item xs={12} sm={8} md={6} key={"loading"}>
                                            <ACard
                                                key={`loading-card`}
                                                title={""}
                                                styleProps={{
                                                    cardStyle: {height:400}
                                                }}
                                            >
                                                <Skeleton animation="wave" height={300} variant="rounded" width="100%" />
                                            </ACard>
                                        </Grid>
                                    )
                                }
                                {
                                    isError && (
                                        <Grid item xs={12} sm={8} md={6} key={"error"}>
                                            <ACard
                                                key={`error-card-${selectedChartConfig?.name}`}
                                                title={selectedChartConfig ? selectedChartConfig?.name : ''}
                                                styleProps={{
                                                    cardStyle: {height:400}
                                                }}
                                            >
                                                <DataNotFound boxStyle={{ height: 300, width: '100%' }} />
                                            </ACard>
                                        </Grid>
                                    )
                                }
                                   {
                                    isSuccess && data.status === 204 &&  (
                                        <Grid item xs={12} sm={8} md={6} key={"error"}>
                                            <ACard
                                                key={`error-card-${selectedChartConfig?.name}`}
                                                title={selectedChartConfig ? selectedChartConfig?.name : ''}
                                                styleProps={{
                                                    cardStyle: {height:400}
                                                }}
                                            >
                                                <DataNotFound boxStyle={{ height: 300, width: '100%' }} />
                                            </ACard>
                                        </Grid>
                                    )
                                }
                            </>
                        )
                }
                
                {chartConfigList?.length > dataEChartConfig?.length && (
                    // retornar os elementos que não foram encontrados no dataEChartConfig dentro de chartConfigList

                    chartConfigList?.filter((config: { id: number }) => {                                
                        return !dataEChartConfig.find(chartConfig => chartConfig.id === config.id)
                    }).map((chartConfig:any, index:number) => {                                
                        return (
                            <Grid item xs={12} sm={8} md={6} key={chartConfig.id}>
                                <ACard
                                    key={`card-${chartConfig.id}-${index}`}
                                    title={chartConfig.name}
                                >
                                        <DataNotFound boxStyle={{ height: 300, width: '100%' }} />
                                </ACard>
                            </Grid>
                        );
                    })
                
                )}
                        
                        
            </Grid>
        </>
    );
}

export default function AnalysisHomePage(){

    const queryClient = new QueryClient();

    return(
        <QueryClientProvider client={queryClient}>
            <AnalysisCharts/>
        </QueryClientProvider>
    )
}